module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<li class=\"pv-h\">\n    <a href=\"javascript:void(0);\" class=\"filter js-filter\">")
    ; __append(escapeFn( selected.title ))
    ; __append("</a>\n    <ul class=\"content\">\n        ")
    ; 
        for (var i = 0; i < data.length; i++) { 
    ; __append("\n            <li><a href=\"javascript:void(0);\" data-index=\"")
    ; __append(escapeFn( i ))
    ; __append("\" data-value=\"")
    ; __append(escapeFn( data[i].value ))
    ; __append("\">")
    ; __append(escapeFn( data[i].title ))
    ; __append("</a></li>\n        ")
    ;  } 
    ; __append("\n    </ul>\n</li>\n<select class=\"dropdown__filter__fake\">\n    ")
    ;  for (var i = 0; i < data.length; i++) { 
    ; __append("\n        <option value=\"")
    ; __append(escapeFn( data[i].value ))
    ; __append("\">")
    ; __append(escapeFn( data[i].title ))
    ; __append("</option>\n    ")
    ;  } 
    ; __append("\n</select>")
  }
  return __output;

}