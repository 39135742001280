/**
 * Feature flag constants
 *
 * @desc JS counterpart of Feature.php class
 */
export const AA_ACCOUNT_COUPON_REDEEM = 'aa_account_coupon_redeem';
export const AA_ADDITIONAL_LOGIN = 'AA_ADDITIONAL_LOGIN';
export const AA_ADDONS = 'AA_ADDONS';
export const AA_ANNUAL_PLAN = 'AA_ANNUAL_PLAN';
export const AA_DYNAMIC_AGREEMENT_COPY = 'AA_DYNAMIC_AGREEMENT_COPY';
export const AA_EDU = 'AA_EDU';
export const AA_GIFT = 'AA_GIFT';
export const AA_MULTI_SUB_PLAN_PICKER = 'AA_MULTI_SUB_PLAN_PICKER';
export const AA_PACKAGE_SWITCH = 'AA_PACKAGE_SWITCH';
export const AA_PAYPAL = 'AA_PAYPAL';
export const AA_PURCHASE = 'AA_PURCHASE';
export const AA_PURCHASE_REVIEW_SCREEN = 'AA_PURCHASE_REVIEW_SCREEN';
export const AVATARS_V2 = 'avatars_v2_enabled';
export const CBSN = 'CBSN';
export const COLD_START_PREMIUM_LOCKS_ENABLED = 'cold_start_premium_locks_enabled';
export const ENHANCED_KIDS_PRIVACY_ENABLED = 'enhanced_kids_privacy_enabled';
export const ETL = 'ETL';
export const LOCAL_LIVE_TV = 'LOCAL_LIVE_TV';
export const MOVIES = 'MOVIES';
export const MVPD = 'MVPD';
export const PIN_CONTROLS = 'PIN_CONTROLS';
export const PLANPICKER_COMPLIANCE_ENABLED = 'planpicker_compliance_enabled';
export const PRICE_WITH_ASTERISK = 'PRICE_WITH_ASTERISK';
export const REDFAST_ENABLED = 'redfast_enabled';
export const REDFAST_PREMIUM_UPSELL_ENABLED = 'redfast_premium_upsell_enabled';
export const REGIONAL_SKUS = 'REGIONAL_SKUS';
export const RENDEZVOUS = 'RENDEZVOUS';
export const RESELLER_DELTA_AUTO_LOGIN = 'reseller_delta_auto_login';
export const RESELLER_DELTA_DEPEVIEW = 'reseller_delta_deepview';
export const RESELLER_DELTA_JOURNEY = 'reseller_delta_journey';
export const RESELLER_DELTA_JOURNEY_SIGN_IN = 'reseller_delta_journey_sign_in';
export const RESELLER_DELTA_JOURNEY_SIGN_UP = 'reseller_delta_journey_sign_up';
export const SCREENTIME_LIMIT_ENABLED = 'screentime_limit_enabled';
export const SHARED_PAYMENT_COMPONENT = 'SHARED_PAYMENT_COMPONENT';
export const SHOW_INNER_PAGES = 'SHOW_INNER_PAGES';
export const SHOW_PICKER = 'show_picker';
export const SHOWHIDE_PASSWORD = 'show_hide_password';
export const SHOWPICKER_WATCHLIST_ENABLED = 'showpicker_watchlist_enabled';
export const SITE_SEARCH = 'SITE_SEARCH';
export const SPORTS_HQ = 'SPORTS_HQ';
export const SWITCH_PROFILE_PIN_ENABLED = 'switch_profile_pin_enabled';
export const TUNE_IN_INFO = 'TUNE_IN_INFO';
export const TVE_GHOST_ACCOUNT = 'tve_ghost_account';
export const USER_PROFILES = 'user_profiles';
